import React from 'react';
import PropTypes from 'prop-types';
import jwt from "jsonwebtoken";

// import * as useAuth from 'src/redux/slices/auth';
import { Redirect } from 'react-router-dom';
import { PATH_APP } from './../../../routes/paths';
import { httpClient } from "./../../../utils/HttpClient";
import { apiUrl } from '../../../constants'


// ----------------------------------------------------------------------

AdminProtect.propTypes = {
  children: PropTypes.node
};

 function AdminProtect({ children, role }) {
    let token = localStorage.getItem("token");
    let decodedToken = {}
    if (token) {
       decodedToken =  jwt.decode(token, { complete: true });
    }

    // if(decodedToken.payload){
    //     return <Redirect to={"/auth/login"} />;
    // }

    if(!(decodedToken.payload.role == 1 || decodedToken.payload.role == 0)){
        return <Redirect to={PATH_APP.members} />;
    }

  return <>{children}</>;
}

export default AdminProtect;