import React from 'react';
// import { PATH_APP } from './../routes/paths';
import { PATH_APP } from '../../routes/paths';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import MessageIcon from '@material-ui/icons/Message';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DoneIcon from '@material-ui/icons/Done';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import HistoryIcon from '@material-ui/icons/History';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import AssessmentIcon from '@material-ui/icons/Assessment';
// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;
const ICONS = {
  peopleIcon: <PeopleIcon />,
  eventIcon: <EventIcon />,
  messageIcon: <MessageIcon />,
  permContactCalendarIcon: <PermContactCalendarIcon />,
  listAltIcon: <ListAltIcon />,
  doneIcon: <DoneIcon />,
  historyDev: <HistoryIcon />,
  gift: <CardGiftcardIcon />,
  packageLog: <TrendingUpIcon />,
  EditLocationIcon: <EditLocationIcon />,
  AssessmentIcon: <AssessmentIcon />,
};

const navConfig = [
  {
    subheader: 'ทั่วไป',
    items: [
      {
        role: 2,
        title: 'ผู้ใช้',
        href: PATH_APP.members,
        icon: ICONS.permContactCalendarIcon,
      },
      {
        role: 2,
        title: 'แก้ไขข้อมูล TOPFAN',
        href: PATH_APP.updateMember,
        icon: ICONS.EditLocationIcon,
      },
      {
        role: 2,
        title: 'ประวัติย้อนหลัง',
        icon: ICONS.packageLog,
        submenu: [
          {
            title: '- อนุมัติ',
            href: PATH_APP.subscribe,
          },
          {
            title: '- ประวัติการเเก้ไข',
            href: PATH_APP.historyLog,
          },
          {
            title: '- ประวัติการสั่งซื้อ',
            href: PATH_APP.packageLog
          }
        ]
      },
      {
        role: 2,
        title: 'รายงาน',
        icon: ICONS.AssessmentIcon,
        submenu: [
          {
            title: '- จำนวนTopfan รายวัน',
            href: PATH_APP.reportPerDay
          },
          {
            title: '- รายชื่อTopfan ทั้งหมด',
            href: PATH_APP.reportTopfan
          }
        ]
      },
      {
        role: 1,
        title: 'จัดการข้อมูลระบบ',
        icon: ICONS.peopleIcon,
        submenu: [
          {
            title: '- ผู้ใช้งาน',
            href: PATH_APP.users,
          },
          {
            title: '- แพ็คเกจ',
            href: PATH_APP.packages,
          },
          {
            title: '- ของสมนาคุณ',
            href: PATH_APP.gifts,
          }
        ]
      }
    ]
  },
];

export default navConfig;
