import React, { lazy } from 'react';
import { PATH_APP } from './paths';
import { Redirect } from 'react-router-dom';
import Layouts from '../Layouts/Login';

// ----------------------------------------------------------------------

const LoginRoutes = {
  path: '/auth',
  layout: Layouts,
  routes: [
    {
      exact: true,
      path: PATH_APP.login,
      component: lazy(() => import('./../views/Login'))
    }
  ]
};

export default LoginRoutes;
