export const apiUrl = process.env.NODE_ENV != "development"? "https://topfan.topnews.co.th/api/v1":"http://localhost:8081/api/v1";
export const imageUrl = process.env.NODE_ENV != "development"? "https://topfan.topnews.co.th":"http://127.0.0.1:8081";

 
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

//// console.log("🚀 ~ file: index.js ~ line 14 ~ process.env.NODE_ENV", process.env.NODE_ENV)

export const server = {
  LOGIN_URL: "login",
  VIDEO_URL: "video",
  INVOICE_URL: "invoice",
  CATEGORY_VIDEO_URL: "videosCategory",
  PRICE_VIDEO_URL: "videoPrice",
  REFRESH_TOKEN_URL: "refresh/token",
  BANK_URL: "banks",
  REGISTER_URL: "register",
  USER_URL: "users",
  TRANSACTION_URL: "transaction",
  REPORT_URL: "report",
  TOKEN_KEY: "token",
  REFRESH_TOKEN_KEY: "refresh_token",
  UPLOADS: ""
};
