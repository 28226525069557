import React from 'react';
// import { PATH_APP } from './../routes/paths';
import { PATH_APP } from '../../routes/paths';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import MessageIcon from '@material-ui/icons/Message';
// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;
const ICONS = {
  peopleIcon: <PeopleIcon />,
  eventIcon: <EventIcon />,
  messageIcon: <MessageIcon />
};

const navConfig = [
  {
    subheader: 'ทั่วไป',
    items: [
      {
        title: 'ผังรายการ',
        href: PATH_APP.insertProgram,
        icon: ICONS.eventIcon,
      },
      {
        title: 'ส่งข้อความทันที',
        href: PATH_APP.adminBoardCast,
        icon: ICONS.messageIcon,
      },
      {
        title: 'ผู้ใช้งาน',
        href: PATH_APP.users,
        icon: ICONS.peopleIcon,
      }
    ]
  },
];

export default navConfig;
