import './App.css';
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import routes, { renderRoutes } from './../src/routes';
import axios from 'axios';
import { apiUrl } from './constants'
import { httpClient } from "./../src/utils/HttpClient";


function App() {
  const history = useHistory();
  const path = useLocation().pathname;

  useEffect(()=>{
    const token = localStorage.getItem("token");
    // // console.log("🚀 ~ file: App.js ~ line 19 ~ useEffect ~ token", !token)
    // if(token && path === "/auth/login"){history.push("/members")};
    if(!token){
      history.push("/auth/login")
    }
  }, [])

  

  useEffect(()=>{
    const token = localStorage.getItem("token");

    // // console.log("🚀 ~ file: App.js ~ line 19 ~ useEffect ~ token", !token)

    if(token && path === "/auth/login"){history.push("/members")};
    if(!token){
      history.push("/auth/login")
    }

    // httpClient.get(apiUrl+'/checkLogin')

    // if(path !== "/auth/login"){
    //   else{
    //     axios({
    //       method: "POST",
    //       url: apiUrl+"/check-login",
    //       headers: {"Content-Type": "application/json"},
    //       data: JSON.stringify({token})
    //     }).then(res=>{
    //       if(res.data.status !== "success"){
    //         localStorage.removeItem("token");
    //         history.push("/auth/login")  
    //       }
    //     }).catch(e=>{
    //     })
    //   }
    // }
  }, [path])

  return (
    <>
      {renderRoutes(routes)}
    </>
  );
}

export default App;
