function path(root, sublink) {
  return `${root}${sublink}`;
}
const ROOTS = {
  main:"",
  program: "/program",
  auth:"/auth"
};

export const PATH_APP = {
  main: ROOTS.program,
  insertProgram: path(ROOTS.program, '/insert'),
  adminBoardCast: path(ROOTS.main, '/admin-broadcast'),
  users: path(ROOTS.main, '/users'),
  gifts: path(ROOTS.main, '/gifts'),
  members: path(ROOTS.main, '/members'),
  packages: path(ROOTS.main, '/packages'),
  subscribe: path(ROOTS.main, '/subscribe'),
  updateMember: path(ROOTS.main, '/update-member'),
  historyLog: path(ROOTS.main, '/history-log'),
  packageLog: path(ROOTS.main, '/history-package'),
  reportPerDay: path(ROOTS.main, '/logs-purchase'),
  reportTopfan: path(ROOTS.main, '/report-topfan'),
  login: path(ROOTS.auth, '/login'),
  test: path(ROOTS.main, '/test'),
};
