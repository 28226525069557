import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import navConfig from './configNav';
import StarBorder from '@material-ui/icons/StarBorder';
import { Link } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useLocation, useHistory } from 'react-router-dom';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import jwt from "jsonwebtoken";
import Collapse from '@material-ui/core/Collapse';

import { Redirect } from 'react-router-dom';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menu, setMenu] = React.useState(false);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

// console.log("🚀 ~ file: DashboardLayout.js ~ line 21 ~ navConfig", navConfig)
navConfig.map((row)=>{
// console.log("🚀 ~ file: DashboardLayout.js ~ line 101 ~ navConfig.map ~ row", row)

})

const logOut = ()=>{
  localStorage.removeItem("token");
  history.push("/auth/login")
}

const CustomLink = to => {
 return   <Link to={to}  />;
}

const handleClick = () => {
  setMenu(!menu);
};

const rendersub = (items = [])=>{
  let arrayPosition = [];
  let s = items.map((row, index)=>{
    let token = localStorage.getItem("token");
    let decodedToken =  jwt.decode(token, { complete: true });

    if(decodedToken.payload.role == row.role || decodedToken.payload.role == 1 ||  decodedToken.payload.role == 0){
      if(row.submenu) {
        return (
          <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              {row.icon}
            </ListItemIcon>
            <ListItemText primary={row.title} />
            {menu ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          {row.submenu.map((sub, indexsub) => {
            return(
              <>
                <Collapse in={menu} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button component={ RouterLink } className={classes.nested} to={sub.href}>
                      <ListItemIcon>
                      </ListItemIcon>
                      <ListItemText primary={sub.title} />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )
          })}
          </>
        )
      }else{
        return (
          <ListItem  button key={index}  component={ RouterLink } to={row.href}>
            <ListItemIcon>{row.icon}</ListItemIcon>
            <ListItemText primary={row.title}/>
          </ListItem>
        )
      }

    }
  })
  return s
}

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Topnews Subscribe
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {navConfig.map((text, index) => {
            return(
              <>
              <ListSubheader
              style={{ paddingLeft:"15px" }}
              disableSticky
              disableGutters
            >
              {text.subheader}
            </ListSubheader>
              {text.items && text.items.length>0?rendersub(text.items):''}
            </>
            )
            })}

        <ListItem  button onClick={logOut}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary={'Logout'}/>
      </ListItem>

        </List>
        <Divider />

      </Drawer>

    </div>
  );
}
