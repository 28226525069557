import React from 'react';
import PropTypes from 'prop-types';
import jwt from "jsonwebtoken";

// import * as useAuth from 'src/redux/slices/auth';
import { Redirect } from 'react-router-dom';
import { PATH_APP } from './../../../routes/paths';
import { httpClient } from "./../../../utils/HttpClient";
import { apiUrl } from '../../../constants'


// ----------------------------------------------------------------------

LoginProtect.propTypes = {
  children: PropTypes.node
};

 function LoginProtect({ children, role }) {
    let token = localStorage.getItem("token");
    // let checkLogin = 
    if(!token){
      return <Redirect to={PATH_APP.login} />;
    }else{
      console.log("passs")
    }

  return <>{children}</>;
}

export default LoginProtect;