import React, { lazy } from 'react';
import { PATH_APP } from './paths';
import Layouts from '../Layouts/Dashboard';
import AamitProtect from './../components/Auth/AdminProtect';
import LoginProtect from './../components/Auth/loginProtect';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  guard: LoginProtect,
  layout: Layouts,
  routes: [
    {
      exact: true,
      path: PATH_APP.adminBoardCast,
      component: lazy(() => import('./../views/broadcast'))
    },
    {
      exact: true,
      path: PATH_APP.insertProgram,
      component: lazy(() => import('./../views/programs/insert'))
    },
    {
      exact: true,
      path: PATH_APP.gifts,
      component: lazy(() => import('./../views/gifts'))
    },
    {
      exact: true,
      path: PATH_APP.users,
      guard: AamitProtect,
      component: lazy(() => import('./../views/users'))
    },
    {
      exact: true,
      guard: LoginProtect,
      path: "/",
      component: lazy(() => import('./../views/members'))
    },
    {
      exact: true,
      guard: LoginProtect,
      path: PATH_APP.members,
      component: lazy(() => import('./../views/members'))
    },
    {
      exact: true,
      guard: LoginProtect,
      path: PATH_APP.updateMember,
      component: lazy(() => import('./../views/updateMember'))
    },
    {
      exact: true,
      guard: LoginProtect,
      path: PATH_APP.packages,
      component: lazy(() => import('./../views/packages'))
    },
    {
      exact: true,
      path: PATH_APP.subscribe,
      component: lazy(() => import('./../views/subscribe'))
    },
    {
      exact: true,
      path: PATH_APP.historyLog,
      component: lazy(() => import('./../views/historyLog'))
    },
    {
      exact: true,
      path: PATH_APP.packageLog,
      component: lazy(() => import('./../views/packageLog'))
    },
    {
      exact: true,
      path: PATH_APP.reportPerDay,
      component: lazy(() => import('./../views/reportPerDay'))
    },
    {
      exact: true,
      path: PATH_APP.reportTopfan,
      component: lazy(() => import('./../views/reportTopfan'))
    },
    {
      exact: true,
      path: PATH_APP.test,
      component: lazy(() => import('./../views/test'))
    },
  ]
};

export default HomeRoutes;
